button {
  border: none;
}

.genericButtonDefaults {
  transition: all 0.2s;
}
.genericButtonDefaults, .genericButtonDefaults:active, .genericButtonDefaults:focus {
  outline: 0;
}
.genericButtonDefaults:hover {
  transform: translateY(-3px);
}
.genericButtonDefaults:active {
  transform: translateY(-1px);
}